import React from "react"
import styled from 'styled-components'
import { useQuery } from 'react-query'
import {
  Buttons, Flex,
  GreenBarSection, HeaderBanner, LightBlueSection,
  Text, WhiteSection, Vis, MOBILE_ONLY_CSS_WRAPPER,
  TABLET_ONLY_CSS_WRAPPER, ButtonGreen
} from '../../components/common'
import Layout from "../../components/layout"
import { navTo } from '../../utils/navigation'
import api from '../../utils/api'
import { Breadcrumb } from "../../components/Breadcrumb"
import GlobalStoreContext from '../../components/GlobalContext'
import { IS_PROD } from "../../environment"
import Toggle from 'react-toggle'
import "react-toggle/style.css" // for ES6 modules

const GridContainer = styled.div`
margin-top: 50px;
display: grid;
grid-template-columns: 50% 50%;
grid-gap: 10px;
`

const GridItem = styled.div`
display: flex;
align-items: center;
justify-content: center;
`

// outter container set to relative so the Child can have an absolute position
const RelativeFlexMobile = styled(Flex)`
flex-direction: column;
position: relative;
`
//  This is the work around for iOS fullscreen issue
const MobileFullPageAbsoluteDiv = styled.div`
display:flex;
flex-direction:column;
top:0;
right:0;
left:0;
bottom:0;
position:absolute;
`

const ErrorBootstrapSection = styled.div`
  margin: 20px 18px;
  ${TABLET_ONLY_CSS_WRAPPER('margin: 20px 16%')}
`

const DurationBox = styled.input`
  background: #FFFFFF;
  border: 1px solid #005564;
  text-align: right;
  width: 105px;
  height: 25px;
  font-size: 18px;
  line-height: 18px;
  margin: 0px 5px;
`


const ConfigYourConsent = "Configure your consent"
const SubTextData = "Customise the options for this consent"

const quickActions = [{
  name: '0 seconds',
  value: 0
}, {
  name: '1 hour',
  value: 60 * 60
}, {
  name: '1 day',
  value: 24 * 60 * 60
}, {
  name: '1 week',
  value: 7 * 24 * 60 * 60
}, {
  name: '1 month',
  value: 30 * 24 * 60 * 60
}, {
  name: '1 year',
  value: 365 * 24 * 60 * 60
}]


const ButtonDelay = (navigateFn, payload) => {
  setTimeout(() => {
    if (payload) {
      navigateFn(payload)
    } else {
      navigateFn()
    }
  }, 300)
}

const ConfigureYourConsent = () => {
  const userDetail = api.useGetProMember()
  const [durationSec, setDurationSec] = React.useState(0)
  const [par, setPar] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState(null)

  const { amendConsent } = React.useContext(GlobalStoreContext)


  let { isLoading: userIsLoading, data: userData } = useQuery('users', async () => {
    return new Promise((resolve, reject) => {
      api.getUserDetail().then((returnData) => resolve(returnData.data)).catch(err => reject(err))
    })
  }, {
    retry: 1,
    onError: (error) => {
      const { response } = error
      if (!response || response.status === 403) {
        // not authorized route back to intro page
        navTo.introduction_1()
      }
    },
    refetchOnWindowFocus: false
  })

  const handleChange = (e) => {
    const srcval = e.target.value
    setDurationSec(isNaN(parseInt(srcval)) ? 0 : parseInt(srcval))
  }

  const validateInputBeforeNext = () => {
    setErrorMessage(null)
    if (durationSec >= 0 && durationSec <= 31536000) {
      api.createUser().then(() => {
        if(amendConsent !== null) {
          ButtonDelay(navTo.selectyourdata_6, { durationSec, par, isCommercial: userDetail.isSuccess ? userDetail.data?.isPro : true })
        } else {
          ButtonDelay(navTo.selectyourbank_5, { durationSec, par })
        }
      }).catch(() => {
        console.error("Failed to create user")
        setErrorMessage("Unable to create user, please try again")
        // TODO : need to display error message
      })
    } else {
      setErrorMessage("Duration must be between 0 and 31536000")
    }
  }

  const ButtonGroup = () => {
    return (
      <Flex flex="col">
        {errorMessage && <Flex flex="col">
          <ErrorBootstrapSection className="bootstrap " style={{ marginBottom: '0px' }}>
            <div className="alert alert-danger">{errorMessage}</div>
          </ErrorBootstrapSection>
        </Flex>
        }
        <Buttons.BackNextButtons
          backOnClick={() => ButtonDelay(navTo.dashboard)}
          nextOnClick={() => validateInputBeforeNext()}
        />
      </Flex>

    )
  }

  const quickActionSet = (value) => {
    setDurationSec(value)
  }

  return (
    <Layout.ColumnLayout fitToHeightOnMobile={true} title={ConfigYourConsent}>
      <HeaderBanner flex="nogrow" withStep={true} hamburger={false} commercial={true} commercialProperties={userData} userIsLoading={userIsLoading}>
      </HeaderBanner>
      {/* mobile only */}

      <Vis.MobileOnly flex="col">
        <RelativeFlexMobile>
          <MobileFullPageAbsoluteDiv>
            <WhiteSection flex="col nogrow" style={{ margin: '11px 10px 0px 10px' }}>
              <Vis.MobileOnly><Text.Steps>Step 1 of 6</Text.Steps></Vis.MobileOnly>
              <Text.H1 style={{ marginTop: '0px', marginBottom: '16px' }}>{ConfigYourConsent}</Text.H1>
              <GreenBarSection style={{ marginBottom: '21px' }}>{SubTextData}</GreenBarSection>
            </WhiteSection>

            {/* banks */}
            <LightBlueSection flex="col hcenter" style={{ margin: '0px', padding: '25px 28px', overflowY: 'auto' }}>
              <Flex flex="col">
                <Flex flex="row nogrow vcenter">
                  <Text.HelperText>Duration (seconds):</Text.HelperText>
                  <DurationBox inputMode="numeric" type="text" pattern="\d*" value={durationSec} onChange={handleChange} maxLength="8" />
                  <Text.SmallText>Max 31,536,000</Text.SmallText>
                </Flex>
                <GridContainer>
                  {quickActions.map((qa, inx) => {
                    return <GridItem key={inx}>
                      <Buttons.ButtonOutline onClick={() => quickActionSet(qa.value)}>{qa.name}</Buttons.ButtonOutline>
                    </GridItem>
                  })}
                </GridContainer>
                {/* <Flex flex="row nogrow vcenter" style={{ marginTop: '25px' }}>
                  <Text.HelperText style={{ marginRight: '5px', height: '20px' }}>Pushed Authroisation Request (PAR):</Text.HelperText>
                  <Toggle
                    id='par'
                    defaultChecked={par}
                    onChange={() => setPar(!par)}
                  />
                </Flex> */}
              </Flex>
            </LightBlueSection>
            <WhiteSection flex="row nogrow" style={{ marginTop: '20px' }}>
              <ButtonGroup />
            </WhiteSection>
          </MobileFullPageAbsoluteDiv>
        </RelativeFlexMobile>
      </Vis.MobileOnly>

      <Vis.TabletOnly flex="col">
        <WhiteSection flex="col nogrow" style={{ margin: '0px 35px 0px 38px' }}>
          <Text.H1>{ConfigYourConsent}</Text.H1>
          <GreenBarSection>{SubTextData}</GreenBarSection>
        </WhiteSection>
        {/* banks */}
        <LightBlueSection flex="col hcenter" style={{ margin: '0px 60px', padding: '25px 20px' }}>
          <Flex flex="col">
            <Flex flex="row nogrow vcenter">
              <Text.HelperText>Duration (seconds):</Text.HelperText>
              <DurationBox inputMode="numeric" type="text" pattern="\d*" value={durationSec} onChange={handleChange} maxLength="8" />
              <Text.SmallText>Max 31,536,000</Text.SmallText>
            </Flex>
            <GridContainer>
              {quickActions.map((qa, inx) => {
                return <GridItem key={inx}>
                  <Buttons.ButtonOutline onClick={() => quickActionSet(qa.value)}>{qa.name}</Buttons.ButtonOutline>
                </GridItem>
              })}
            </GridContainer>
            {/* <Flex flex="row nogrow vcenter" style={{ marginTop: '25px' }}>
              <Text.HelperText style={{ marginRight: '5px', height: '20px' }}>Pushed Authroisation Request (PAR):</Text.HelperText>
              <Toggle
                id='par'
                defaultChecked={par}
                onChange={() => setPar(!par)}
              />
            </Flex> */}
          </Flex>
        </LightBlueSection>
        <WhiteSection flex="row nogrow" style={{ margin: '40px 0px' }}>
          <ButtonGroup />
        </WhiteSection>
      </Vis.TabletOnly>

      <Vis.DesktopOnly flex="col">
        <WhiteSection flex="row" style={{ margin: '52px 50px 0px 100px' }}>
          <WhiteSection flex="col w50" style={{ padding: '0px' }}>
            <WhiteSection flex="col nogrow">
              <Text.H1>{ConfigYourConsent}</Text.H1>
              <GreenBarSection>{SubTextData}</GreenBarSection>
            </WhiteSection>
            <WhiteSection flex="row" style={{ marginTop: "90px", padding: "0px 0px", width: '424px' }}>
              <ButtonGroup />
            </WhiteSection>
          </WhiteSection>
          <Flex flex="w50 col" style={{ paddingTop: '35px' }}>
            <LightBlueSection flex="col nogrow" style={{ margin: '0px 10px', padding: '25px 40px', minHeight: '350px' }}>
              <Flex flex="col">
                <Flex flex="row vcenter">
                  <Text.HelperText>Duration (seconds):</Text.HelperText>
                  <DurationBox inputMode="numeric" type="text" pattern="\d*" value={durationSec} onChange={handleChange} maxLength="8" />
                  <Text.SmallText>Max 31,536,000</Text.SmallText>
                </Flex>
                <GridContainer>
                  {quickActions.map((qa, inx) => {
                    return <GridItem key={inx}>
                      <Buttons.ButtonOutline onClick={() => quickActionSet(qa.value)}>{qa.name}</Buttons.ButtonOutline>
                    </GridItem>
                  })}
                </GridContainer>
                {/* <Flex flex="row nogrow vcenter" style={{ marginTop: '25px' }}>
                  <Text.HelperText style={{ marginRight: '5px', height: '20px' }}>Pushed Authroisation Request (PAR):</Text.HelperText>
                  <Toggle
                    id='par'
                    defaultChecked={par}
                    onChange={() => setPar(!par)}
                  />
                </Flex> */}
              </Flex>
            </LightBlueSection>
          </Flex>

        </WhiteSection>
      </Vis.DesktopOnly>

    </Layout.ColumnLayout>
  )
}

export default ConfigureYourConsent
